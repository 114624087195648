<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="6">
          <h3>
            <span v-if="createType == 'edit'">
              Edit Company Information
              <v-progress-circular indeterminate class="ml-3" color="primary">
              </v-progress-circular>
            </span>
            <span v-if="createType == 'add'">Add Company</span>
          </h3>

          <v-card v-if="error" class="danger mb-6">
            <v-card-subtitle class="red--text">
              There was an error.
            </v-card-subtitle>
            <v-card-text class="red--text">{{ error }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            <span v-if="createType == 'edit'">Edit Company Information</span>
            <span v-if="createType == 'add'">Add Company</span>
          </h3>

          <p class="py-4">
            Please fill out the form below to add a company. After creating a
            company, you will need to associate accounts to the company so they
            can log in.
          </p>

          <v-form @submit="submitCreateCompany" ref="form" v-model="form.valid">
            <v-text-field
              v-model="companyData.name"
              label="Company Name"
              :rules="form.rules.required"
              outlined
            />

            <v-text-field
              label="Company Phone Number"
              placeholder="Company Phone Number"
              outlined
              v-model="companyData.phone"
              :rules="form.rules.phoneRules"
              maxlength="10"
            />

            <v-text-field
              label="Company Website"
              placeholder="Company Website"
              outlined
              v-model="companyData.website"
            />

            <v-textarea
              v-model="companyData.description"
              label="Description"
              outlined
            />

            <v-row class="mb-5">
              <v-col cols="12" class="pb-0 mb-n2">
                <div v-if="companyData.addresses.length > 0" class="mb-5">
                  <p class="grey--text text-subtitle-2 mb-2">
                    List of added address:
                  </p>

                  <v-data-table
                    :headers="header_address"
                    item-key="order"
                    :items="companyData.addresses.filter((el) => !el.archived)"
                    :items-per-page="5"
                    @click:row="handle_updating_address($event)"
                    v-model="selected_address"
                    show-select
                  >
                    <template v-slot:[`item.address`]="{ item }">
                      {{ item.street_number }}
                      {{ item.street_name }}
                      {{ item.street_type }}
                      {{ item.street_direction }}
                      {{ item.unit }}
                      {{ item.city }}
                      {{ item.province }}
                      {{ item.postal_code }}
                    </template>
                  </v-data-table>

                  <v-btn
                    v-if="selected_address.length > 0"
                    @click="handle_delete_address()"
                    color="error"
                    class="mt-2"
                  >
                    delete
                  </v-btn>
                </div>

                <div>
                  <v-btn
                    v-if="!adding_address && !updating_address"
                    @click="adding_address = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                    Add Address
                  </v-btn>
                </div>

                <Address
                  v-if="adding_address || updating_address"
                  :adding_address="adding_address"
                  :updating_address="updating_address"
                  :address_data="new_addresses"
                  :main_address_data="companyData.addresses"
                  :no_time_at_residence="true"
                  :rule="form.rules"
                  @save_add="handle_save_address"
                  @save_update="handle_save_update_address"
                  @close="
                    adding_address = false;
                    updating_address = false;
                  "
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0 mb-n2">Company Type</v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lender"
                  label="Lender"
                  hide-details
                  @change="handle_company_type_lender_change"
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.appraiser"
                  label="Appraiser"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lawyer"
                  label="Lawyer"
                  hide-details
                />
              </v-col>
            </v-row>

            <br />
            <br />

            <v-select
              v-if="companyData.lender"
              :items="companyClassOptions"
              v-model="companyData.lending_profile.lender_type"
              label="Lender Class"
              :rules="form.rules.required"
              outlined
            />

            <v-select
              v-if="companyData.lender"
              :items="companyGroupOptions"
              v-model="companyData.lending_profile.tier"
              label="Group Name"
              outlined
            />

            <v-select
              v-if="companyData.lender"
              :items="kbn_stgs"
              v-model="companyData.kbn_stg"
              label="Lender Stage"
              outlined
            />

            <v-row v-if="companyData.lender">
              <v-col cols="12" class="pb-0 mb-n2">Service Areas</v-col>
            </v-row>

            <v-row v-if="companyData.lender">
              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.BC"
                  label="BC"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.AB"
                  label="Alberta"
                  hide-details
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  v-model="companyData.lending_profile.ON"
                  label="Ontario"
                />
              </v-col>
            </v-row>

            <v-divider />

            <v-row>
              <v-col>
                <v-switch
                  v-model="companyData.conflict_of_interest"
                  label="Conflict of Interest"
                />
              </v-col>
            </v-row>

            <v-row v-if="companyData.conflict_of_interest">
              <v-col>
                <v-textarea
                  v-model="companyData.direct_conflict_of_interest"
                  label="Direct conflict of interest"
                  outlined
                  auto-grow
                />
              </v-col>

              <v-col>
                <v-textarea
                  v-model="companyData.indirect_conflict_of_interest"
                  label="Indirect conflict of interest"
                  outlined
                  auto-grow
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="companyData.active"
                  label="Currently An Active Company"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-card v-if="submitError" class="danger mb-6">
                  <v-card-subtitle class="red--text">
                    There was an error.
                  </v-card-subtitle>
                  <v-card-text class="red--text">{{ submitError }}</v-card-text>
                </v-card>

                <v-btn
                  v-if="createType == 'add'"
                  :disabled="formLoading"
                  @click="submitCreateCompany"
                >
                  Create Company
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  />
                </v-btn>

                <v-btn
                  v-if="createType == 'edit'"
                  :disabled="formLoading"
                  @click="submitUpdateCompany"
                >
                  Update Company
                  <v-progress-circular
                    indeterminate
                    class="ml-3"
                    color="primary"
                    v-if="formLoading"
                  />
                </v-btn>

                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'edit'"
                  @click="$router.go(-1)"
                >
                  Cancel
                </v-btn>

                <v-btn
                  dark
                  class="ml-4"
                  v-if="createType == 'add'"
                  @click="
                    $router.push({ path: `/dashboard/admin/company/list` })
                  "
                >
                  Go To Company List
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";
import Address from "@/components/customer/AddAddress.vue";

export default {
  name: "company_add",
  props: ["company_id"],

  components: {
    Address,
  },

  data() {
    return {
      createType: "",
      formLoading: false,
      componentLoading: true,
      error: "",
      submitError: "",

      adding_address: false,
      updating_address: false,

      form: {
        valid: false,
        rules: {
          required: [(v) => !!v || "Name is required"],
          companyPhoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",

            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          phoneRules: [
            (v) =>
              (v && /\d/g.test(v)) ||
              "Phone must be numbers only (ex. 7803334444)",
            (v) => !!v || "Phone number is required",
            (v) =>
              (v && v.length == 10) ||
              "Phone number must be 10 digits no spaces or characters (format 7803334444)",
          ],
          emailRules: [
            (v) => (v && !!v) || "E-mail is required",
            (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
          ],

          number: [
            (v) =>
              v === "" ||
              v === null ||
              /^\d+$/.test(v) ||
              "Input must be a number",
          ],

          month: [
            (v) =>
              v === "" ||
              v === null ||
              /^\d+$/.test(v) ||
              "Input must be a number",
            (v) => (v >= 0 && v <= 11) || "Input must be between 0 and 11",
          ],

          date: [
            (v) =>
              (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
              "Invalid date. Format should be YYYY-MM-DD",
          ],
        },
      },

      new_addresses: {},
      selected_address: [],

      companyData: {
        name: null,
        description: null,
        verified: null,
        active: null,
        lender: false,
        appraiser: false,
        lawyer: false,
        conflict_of_interest: false,
        direct_conflict_of_interest: null,
        indirect_conflict_of_interest: null,
        
        lending_profile: {
          lender_type: null,
          tier: null,
          AB: false,
          BC: false,
          ON: false,
        },

        kbn_stg: null,

        phone: null,

        website: null,

        addresses: [],
      },

      companyGroupOptions: [
        { value: null, text: "None" },
        { value: "tier 1", text: "Round 1" },
        { value: "tier 2", text: "Round 2" },
        { value: "tier 3", text: "Round 3" },
        { value: "tier 4", text: "Round 4" },
        { value: "hail mary", text: "Screw it.... Hail Mary!" },
      ],

      companyTypeOptions: [
        { value: null, text: "None" },
        { value: "lender", text: "Lender" },
        { value: "appraiser", text: "Appraiser" },
        { value: "legal", text: "Legal" },
        { value: "other", text: "Other" },
      ],

      companyClassOptions: [
        { value: "MIC", text: "Mortgage Investment Company" },
        { value: "Private", text: "Private Company" },
        { value: "Institutional", text: "Institutional" },

        { value: "Broker", text: "Broker" },
        { value: "Other", text: "Other" },
      ],

      kbn_stgs: [{ value: null, text: "None" }],

      header_address: [
        { text: "Address", value: "address" },
        { text: "Present", value: "present" },
      ],
    };
  },

  methods: {
    async submitCreateCompany() {
      try {
        this.formLoading = true;
        this.submitError = "";

        this.$refs.form.validate();

        if (this.form.valid) {
          // create company entry
          const company_entry = await API().post(
            "/api/internal-admin/company",
            this.companyData
          );

          console.log("company entry response: ", company_entry);

          if (company_entry.status == 201) {
            this.formLoading = false;
            this.$router.push(
              `/dashboard/admin/company/read/${company_entry.data.id}`
            );
          }

          if (company_entry.status !== 201) {
            this.formLoading = false;
            this.submitError =
              "Company was not created, Kindly check your entry and try again.";
          }
        } else {
          this.formLoading = false;
        }
      } catch (error) {
        this.formLoading = false;
        this.submitError = error;
        console.log(error);
      }
    },

    submitUpdateCompany() {
      this.formLoading = true;
      this.submitError = "";

      this.$refs.form.validate();

      if (this.form.valid) {
        API()
          .patch(`/api/internal-admin/company/`, this.companyData)
          .then((res) => {
            this.formLoading = false;

            // update kanban stage item
            API().post("api/kanban/update_kanban_stage_item", {
              stage_id: this.companyData.kbn_stg,
              item_id: res.data.id,
              type: "Lender on boarding",
              choice: "Archive and Create New",
            });

            this.$router.go(-1);
          })
          .catch((e) => {
            // alert(e);
            this.submitError = e;
            this.formLoading = false;
          });
      } else {
        this.formLoading = false;
      }
    },

    async get_company_info() {
      try {
        let company_info = await API().get(
          `/api/internal-admin/company/${this.company_id}`
        );

        if (company_info.data.kbn_stg !== null) {
          company_info.data.kbn_stg = company_info.data.kbn_stg.id;
        }

        this.companyData = company_info.data;

        if (!this.companyData.lending_profile) {
          this.companyData.lending_profile = {
            lender_type: null,
            tier: null,
            AB: false,
            BC: false,
            ON: false,
          };
        }

        let new_order = 0;

        for (const address of this.companyData.addresses) {
          address.order = new_order;
          new_order++;
        }

        // console.log("company data: ", this.companyData);

        this.componentLoading = false;
      } catch (error) {
        alert(error);
        alert("error loading company information");
      }
    },

    async getKanbanStages() {
      try {
        const res = await API().get("api/kanban/get_kanban_lender_data");

        res.data.forEach((el) => {
          this.kbn_stgs.push({
            value: el.id,
            text: el.stage_name,
          });
        });

        console.log();
      } catch (error) {
        console.log(error);
        alert("error loading kanban stages");
      }
    },

    handle_company_type_lender_change() {
      if (this.companyData.lender == false) {
        this.companyData.lending_profile.lender_type = null;
        this.companyData.lending_profile.tier = null;
        this.companyData.lending_profile.AB = false;
        this.companyData.lending_profile.BC = false;
        this.companyData.lending_profile.ON = false;
        this.companyData.kbn_stg = null;
      }
    },

    // address methods
    handle_save_address(data) {
      let new_order = 0;

      if (this.companyData.addresses.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.companyData.addresses.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.companyData.addresses.push(data);
      this.adding_address = false;
    },

    handle_save_update_address(data) {
      const index = this.companyData.addresses.findIndex(
        (item) => item.order === data.order
      );

      this.companyData.addresses.splice(index, 1, data);

      this.updating_address = false;
    },

    handle_updating_address(data) {
      if (this.adding_address) {
        alert(
          "You are currently adding a new address. Please finish adding it first before updating an existing address."
        );

        return;
      } else {
        this.updating_address = true;

        this.new_addresses = data;
      }
    },

    handle_delete_address() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_address.forEach((el) => {
          const index = this.companyData.addresses.findIndex(
            (item) => item.order === el.order
          );

          this.companyData.addresses.splice(index, 1);
        });

        this.selected_address = [];
      } else {
        console.log("deleting address was canceled.");
        this.selected_address = [];
      }
    },
  },

  async mounted() {
    this.getKanbanStages();

    if (this.$route.name == "company_add") {
      this.createType = "add";
      this.componentLoading = false;
    } else if (this.$route.name == "company_edit") {
      await this.get_company_info();
      this.createType = "edit";
      this.componentLoading = false;
    }
  },
};
</script>
